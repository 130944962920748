<template>

    <div>
        <v-container id="dashboard" fluid tag="section">
            <v-text-field class="mt-4" label="اكتب للبحث" outlined append-icon="mdi-magnify" v-model="search">
            </v-text-field>
           
            <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1"
                >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title> {{ $t("Drawer.about") }} </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs" v-on="on">
                                    {{ $t("add_new") }}
                                </v-btn>
                            </template>
                            <v-form ref="form" v-model="valid">
                                <v-card>

                                    <v-toolbar dark color="primary lighten-1 mb-5">
                                        <v-toolbar-title>
                                            <h3 style="color:#fff"> {{formTitle}}</h3>
                                        </v-toolbar-title>
                                        <v-spacer />
                                        <v-btn @click="close()" icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>

                                    <v-card-text>
                                        <v-container>

                                            <v-row>
                                                <v-col class="py-0" cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.translations[0].title"
                                                        :label="$t('datatable.title')+' '+$t('arbic')" outlined>
                                                    </v-text-field>
                                                </v-col>

                                               


                                                <v-col class="py-0" cols="12" sm="12" md="12">
                                                    <v-text-field v-model="editedItem.translations[1].title"
                                                        :label="$t('datatable.title')+' '+$t('en')" outlined>
                                                    </v-text-field>
                                                </v-col>

                                                 <v-col class="py-0" cols="12" sm="12" md="12">
                                                    <v-select v-model="editedItem.about_as_cats_id"  :label="$t('cats')" :items="cats"
                                                         outlined item-text="name"
                                                        item-value="id" ></v-select>
                                                </v-col>


                                                <v-col class="py-0" cols="12" sm="12" md="12">
                                                    <v-textarea v-model="editedItem.translations[0].description"
                                                        :label="$t('datatable.description')+' '+$t('arbic')" outlined>
                                                    </v-textarea>
                                                </v-col>



                                                <v-col class="py-0" cols="12" sm="12" md="12">
                                                    <v-textarea v-model="editedItem.translations[1].description"
                                                        :label="$t('datatable.description')+' '+$t('en')" outlined>
                                                    </v-textarea>
                                                </v-col>





                                            </v-row>

                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red darken-1" text @click="close()">{{ $t("close") }}
                                        </v-btn>
                                        <v-btn :loading="loadSave" color="blue darken-1" @click="save()" text>
                                            {{ $t("save") }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">





                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-5" @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs"
                                v-on="on">mdi-pencil</v-icon>
                        </template>
                        <span>{{ $t("edite") }} </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="deleteItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                                mdi-delete</v-icon>
                        </template>
                        <span>{{$t('Delete')}}</span>
                    </v-tooltip>



                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
    import Axios from "axios";
    export default {
        data() {
            return {
                desserts: [
                   
                ],
                cats: [],
                dialog: false,
                loadSave: false,
                editedIndex:-1,
                editedItem: {
                    translations:[
                         {
                        title:'',
                        description:''
                    },
                     {
                        title:'',
                         description:''
                    }
                    ],
                    id: "",
                    title: "",
                
                    description: "",
                   
                    about_as_cats: ""

                },
                items: [

                ],
                headers: [
                    {
                        text: this.$t('datatable.title'),
                        align: "start",
                        value: "title"
                    },  {
                        text: this.$t('cats'),
                        value: "about_as_cats.name"
                    },

                    {
                        text: this.$t('datatable.description'),
                        align: "start",
                        value: "description"
                    },
                  
                    {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    }
                ],
                right: null
            }
        },

        methods: {

            editItem(item) {
                this.editedIndex = this.desserts.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            close() {
                this.dialog = false;
                // this.editedItem ={};
                   this.editedItem={
                    translations:[
                         {
                        title:'',
                        description:''
                    },
                     {
                        title:'',
                         description:''
                    }
                    ],
                    id: "",
                    title: "",
                
                    description: "",
                   
                    about_as_cats: ""

                };
                // this.$nextTick(() => {
                //     this.editedItem = Object.assign({}, this.defaultItem);
                //     this.editedIndex = -1;
                // });
            },
            initialize() {
                this.loading = true;
                Axios.get("aboutAs", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorizations: "Bearer " +this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.desserts = res.data.data;
                      //  console.log(res.data);
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },


            getCats() {
                
                Axios.get("/aboutAsCats", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorizations: "Bearer " +this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.cats = res.data.data;
                        // console.log(res.data);
                    })
                    .catch(() => {
                        this.loading = false;
                    });

            },

            save() {
                this.loadSave = true;
                if (this.editedIndex > -1) {

                    this.axios
                        .patch("aboutAs/" + this.editedItem.id, this.editedItem, {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorizations: "Bearer " +this.$store.state.AdminInfo.token,
                            },
                        })
                        .then(() => {
                            this.loadSave = false;
                            this.initialize();
                            this.close();
                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                        })
                        .catch(() => {
                            this.loadSave = false;

                            this.$swal.fire({
                                title: "تاكد من ملى المعلومات",
                                text: "",
                                icon: "error",
                                confirmButtonText: "اغلاق",
                            });
                        });
                } else {

                    this.axios
                        .post("aboutAs", this.editedItem)
                        .then(() => {
                            this.loadSave = false;
                            this.initialize();
                            this.editedIndex=-1;
                            this.close();
                            this.$swal.fire({
                                title: this.$t('Added'),
                                text: "",
                                icon: "success",
                                confirmButtonText: this.$t('close'),
                            });
                        })
                        .catch((err) => {
                            err

                            this.loadSave = false;

                        });
                }


            },

        },
            computed: {
      formTitle() {
        return this.editedIndex === -1 ? this.$t('add_new')  :  this.$t('update');
          
      },
    },
        created() {
            this.initialize();
            this.getCats();
        },

    }
</script>